<template>
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'class-3'"
    :headerClass="'fixed-home'"
    :pageDefaultBackLink="'/home'"
    :contentColor="'light'"
    :backButtonClass="'btn-1'"
  >
    <template v-slot:actions-end>
      <ion-button router-link="/settings" class="btn-1">
        <ion-icon slot="icon-only" :icon="settingsSharp"></ion-icon>
      </ion-button>
    </template>

    <template v-slot:ion-content>
      <div class="page-container ion-padding">
        <div class="news-item">
          <div class="content" ref="content">
            <div
              class="photo-section"
              :style="'height:' + photoHeight + 'px;'"
              ref="photoSection"
            >
              <div
                v-if="imageLoaded"
                class="photo"
                :style="'background-image: url(' + news.image_url + ');'"
              ></div>

              <div
                v-else
                class="photo"
                :style="'background-image: url(' + placeholderimg + ');'"
              ></div>
              <div class="info notranslate">
                <div class="tickers">
                  <template v-for="(ticker, key) in tickers" :key="key">
                    <ion-badge color="light" class="ticker">{{
                      ticker
                    }}</ion-badge>
                  </template>
                </div>
              </div>
            </div>

            <div class="info">
              <p class="title">
                {{ news.title }}
              </p>

              <p class="description">
                {{ news.text }}
              </p>
            </div>

            <div class="meta">
              <div
                v-if="news.news_source_info"
                class="source-img"
                :style="
                  'background-image: url(' +
                  server +
                  '/storage/news-sources/' +
                  news.news_source_info.logo +
                  ')'
                "
              ></div>
              <div v-else class="source-img source-img-placeholder"></div>
              <div class="source-meta">
                <p class="source-name" v-if="news.news_source_info">
                  {{ news.news_source_info.name }}
                </p>
                <p class="source-name" v-else>
                  <ion-skeleton-text
                    animated="loading"
                    style="width: 100px"
                  ></ion-skeleton-text>
                </p>
                <p class="time">
                  {{ $filters.signalTimeLT(news.date, momentJsLocale) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="read-more">
          <ion-button
            class=""
            fill="solid"
            expand="block"
            color="primary"
            @click="open()"
          >
            <span>Read More</span>
            <ion-icon :icon="openOutline" slot="end"></ion-icon>
          </ion-button>
        </div>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
import { IonButton, IonIcon, IonBadge, IonSkeletonText } from "@ionic/vue";
import { settingsSharp, openOutline } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
import placeholderimg from "../../assets/img/placeholder-image.jpg";

export default {
  inject: ["store"],
  data() {
    return {
      settingsSharp,
      photoHeight: 0,
      imageLoaded: false,
      placeholderimg,
      openOutline,
    };
  },
  components: {
    IonButton,
    IonIcon,
    "base-layout-2": BaseLayout2,
    IonBadge,
    IonSkeletonText,
  },

  methods: {
    open() {
      window.location.href = this.news.news_url;
    },

    wait(millisecs) {
      return new Promise((resolve) => {
        setTimeout(resolve, millisecs);
      });
    },

    setPhotoHeight(contentWidth) {
      this.photoHeight = (contentWidth / 379) * 214;
    },

    setImage() {
      let self = this;
      var highResImage = new Image();

      highResImage.onload = function () {
        self.imageLoaded = true;
      };

      highResImage.onerror = function () {
        setTimeout(self.setImage, 1000);
        console.log(
          "Image load error for signal with id " +
            self.news.id +
            " failed. Rretrying in a sec"
        );
      };

      highResImage.src = self.news.image_url;
    },
  },

  computed: {
    server() {
      return this.store.server;
    },

    tickers() {
      return JSON.parse(this.store.state.newsInView.tickers);
    },

    news() {
      return this.store.state.newsInView;
    },
  },

  mounted() {
    this.setImage();
    var contentWidth = this.$refs.content.clientWidth;
    this.setPhotoHeight(contentWidth);

    (async () => {
      while (contentWidth <= 0) {
        contentWidth = this.$refs.content.clientWidth;
        this.setPhotoHeight(contentWidth);
        await this.wait(50);
      }
    })();

    window.googleTranslateSelectTag.value = this.googleTranslateLocale;
    window.googleTranslateSelectTag.dispatchEvent(new Event("change"));
  },

  ionViewDidEnter() {
    if (!this.store.state.appReady) {
      this.emitter.emit("appSignedIn");
    }
  },

  created() {},
};
</script>


<style scoped lang="scss">
.page-container {
  background: var(--ion-color-light);
  overflow: auto;
  padding-top: 110px;
}

.news-item {
  background: #fff;
  --padding-start: 0;
  --inner-padding-end: 0;
  margin-bottom: 25px;
  //   box-shadow: 0 7px 51px -49px #aaa;
  border-radius: 20px;

  &:hover,
  &:active {
    .photo {
      transform: scale(1.15);
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;

    > div {
      width: 100%;
    }

    .photo-section {
      height: 215px;
      align-items: flex-end;
      position: relative;
      overflow: hidden;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      // box-shadow: 0 -132px 98px -98px #000 inset;

      .photo {
        height: 100%;
        width: 100%;
        background-size: cover;
        border-top-left-radius: 30px;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.3s;
      }

      .info {
        width: 100%;
        // background: red;
        padding: 16px;

        .tickers {
          //   background: blue;
          width: 100%;
          height: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .ticker {
            margin-right: 10px;
            background-color: rgba(255, 255, 255, 0.5);
          }
        }

        .title {
          p {
            padding: 0;
            margin: 0;
            padding-top: 5px;
            font-weight: 900;
          }
        }
      }
    }

    .info {
      padding: 0 16px;
      color: #000;
      p.title {
        font-weight: 800;
      }
      p.description {
        font-weight: 400;
        font-size: calc(13px + $extra-font-size);
      }
    }

    .meta {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      margin-bottom: 16px;
      box-sizing: border-box;
      color: #000;

      .source-img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-size: cover;
      }

      .source-img-placeholder {
        background: #eee;
      }

      .source-meta {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .source-name {
          font-weight: 700;
          font-size: calc(13px + $extra-font-size);
          color: var(--ion-color-secondary);
        }

        .time {
          color: var(--ion-color-medium-shade);
          font-size: calc(12px + $extra-font-size);
          margin-top: 3px;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

.read-more {
  // padding: 0 16px;
}

ion-skeleton-text {
  border-radius: 1px;
}
</style>